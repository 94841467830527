<script src="../../store.js"></script>
<template>

  <div>
    <AlertComponent :message="successAlert.message" type="nobb-alert-primary"
                    v-if="successAlert.enabled"></AlertComponent>
    <AlertComponent :message="errorAlert.message" type="nobb-alert-warning"
                    v-if="errorAlert.enabled"></AlertComponent>
    <div class="modal fade" id="profileModal" tabindex="-1" aria-hidden="true"
         data-bs-backdrop="true" ref="profileModal">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content center">
          <div class="float-left" style="z-index: 9999">
            <nav class="navbar navbar-expand-lg nobb-nav">
              <a class="navbar-brand" href="#/"><img src="../../assets/branding/Rübe.png"/></a>
              <a href="#/"><h3>NOBB.io</h3></a>
              <input id="search" type="text" class="form-control navbar-search"
                     placeholder="Search for ways and means of innovation" style="visibility: hidden">
              <a href="#" style="display: none">Cancel</a>

              <a href="#" style="margin: 0 16px;" data-bs-toggle="modal" data-bs-target="#profileModal" id="closeModal"
                 v-if="!this.closeButton">Cancel</a>
              <button class="btn" v-on:click="saveChanges()" v-if="!this.closeButton">Save</button>
              <button class="btn" data-bs-toggle="modal" data-bs-target="#profileModal" id="closeModalDone"
                      v-if="this.closeButton">Close
              </button>
            </nav>
          </div>
          <div class="modal-body">
            <!-- Signup Section !-->
            <div class="col-md-6">
              <h4>Your profile</h4>
              <hr style="color: black !important; height: 2px; opacity: 100%; margin-bottom: 50px">

              <div class="form-group">
                <div class="profile-image-placeholder" :style="{color: userSession.company.companyColor}"
                     v-if="!userSession.company.systemFileName">
                  {{ userSession.company.companyName.charAt(0) }}
                </div>

                <div class="profile-image" v-if="userSession.company.systemFileName || this.user.company.logoPreview">
                  <img v-bind:src="VUE_APP_NOBB_API + 'File/' + userSession.company.systemFileName"
                       class="profile-image" v-if="!this.user.company.logoPreview" alt="Logo"/>
                  <img :src="this.user.company.logoPreview" class="profile-image" v-if="this.user.company.logoPreview"
                       alt="Logo"/>
                </div>
              </div>

              <div class="form-group">
                <label for="logo">Logo</label>
                <input type="file" accept="image/*" class="form-control" id="logo" placeholder="Upload your logo"
                       @change="processFileUpload($event)">
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input type="email" class="form-control" id="email" v-model="userSession.email">
              </div>

              <div class="form-group">
                <label for="company">Pen name</label>
                <input type="text" class="form-control" id="company" v-model="userSession.company.companyName">
              </div>

              <div class="form-group">
                <label for="color">Company color</label>
                <input type="color" class="form-control" id="color" v-model="userSession.company.companyColor">
              </div>

              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password" v-model="user.password"
                       placeholder="*************">
                <i>Must consist of at least 5 characters.</i>
              </div>

              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password" v-model="user.passwordValidation"
                       placeholder="*************">
              </div>

              <div class="form-group">
                <label for="password">Your current password</label>
                <input type="password" class="form-control" id="password" v-model="user.currentPassword"
                       placeholder="*************">
                <i>For security reasons, we require your current password to make sure it is you.</i>
              </div>

              <div class="form-group">
                <button class="btn btn-primary form-control" style="margin-top: 30px;" v-on:click="saveChanges()">Save
                  Changes →
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertComponent from '../alert/AlertComponent'
import CompanyService from "../../services/CompanyService";
import UserService from "../../services/UserService";
import EventBus from "../../event-bus";
import {Modal} from "bootstrap";

export default {
  name: 'OverlayProfileComponent',
  components: {AlertComponent},
  props: ['action'],
  data() {
    return {
      errorAlert: {
        enabled: false,
        message: '',
      },
      successAlert: {
        enabled: false,
        message: '',
      },
      closeButton: false,
      user: {
        password: '',
        passwordValidation: '',
        currentPassword: '',
        company: {
          logo: '',
          logoName: '',
          logoPreview: false
        }
      },
      VUE_APP_NOBB_API: process.env.VUE_APP_NOBB_API,
    }
  },
  computed: {
    userSession: function () {
      return this.$store.state.user
    },
  },
  methods: {
    async saveChanges() {

      // Initialize dynamic vars.
      this.successAlert.message = ''
      this.successAlert.enabled = false
      this.errorAlert.enabled = false
      this.closeButton = false

      // Detect if the user is a company owner
      if (this.userSession.isCompanyAdmin === 1) {
        // update company profile and check if a new logo has been uploaded.
        if (this.user.company.logo !== '') {
          // Update logo
          let formData = new FormData()
          formData.append("companyLogoFile", this.user.company.logo)

          await CompanyService.updateCompanyLogo(this.userSession.company.idCompany, formData).then((response) => {
            // Add alert message that the user's logo has been updated.
            this.successAlert.message = "Your logo has been updated"

            // Updating the state so that the user's logo is updated without requiring a logout.
            this.$store.commit('updateLogo', response.data.systemFileName)
          }).catch((error) => {
            console.log(error)
            // Show an error to the user that the file upload did not go as expected.
            this.errorAlert.enabled = true
            this.errorAlert.message = error.response.data.message
          })
        }

        await CompanyService.updateCompanyProfile(this.userSession.company).then(() => {
          this.successAlert.message = this.successAlert.message + " & Your pen name has been updated "

          this.$store.commit('updateCompanyColor', this.userSession.company.companyColor)
          EventBus.$emit("cards-refresh")
        }).catch((error) => {
          this.errorAlert.enabled = true
          this.errorAlert.message = error.response.data.message
        })
      }

      // Update user details
      await UserService.updateUserProfile(this.userSession).then((response) => {
        console.log(response)
        this.successAlert.message = this.successAlert.message + "& Your profile has been updated "
      }).catch((error) => {
        this.errorAlert.enabled = true
        this.errorAlert.message = error.response.data.message
      })

      // Check if the first password field has been set.
      if (this.user.password !== '') {
        // Check if the first password field is the same as the second password field.
        if (this.user.password === this.user.passwordValidation) {
          // Update password
          await UserService.updatePasswordWithOldPassword(this.userSession.idUser, this.user.currentPassword, this.user.password).then(() => {
            // Add alert message that the user's password has been updated.
            this.successAlert.message = this.successAlert.message + "& Your password has been updated."
          }).catch((error) => {
            this.errorAlert.enabled = true
            this.errorAlert.message = error.response.data.message
          })
        } else {
          // Passwords do not match, help, send an alert to the user.
          this.errorAlert.enabled = true
          this.errorAlert.message = "Your passwords do not match."
        }
      }

      if (this.successAlert.message.length > 0 && this.errorAlert.enabled !== true) {
        if (this.successAlert.message.trim()[0] === "&") {
          this.successAlert.message = this.successAlert.message.trim().substring(1)
        }

        this.successAlert.enabled = true
        this.closeButton = true

        let profileModal = Modal.getInstance(document.getElementById(this.$refs['profileModal'].id))
        profileModal.hide()
      }


    },
    processFileUpload: function (event) {
      console.log(event.target.files[0])
      this.user.company.logoName = event.target.files[0].name
      this.user.company.logo = event.target.files[0]
      this.user.company.logoPreview = URL.createObjectURL(event.target.files[0])
    },
  }
}
</script>

<style scoped>
.form-control {
  padding: 10px;
}

.nobb-form-button {
  background: black;
  color: white;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none;
  line-height: 20px;
  height: 40px;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 14px;
}

</style>
