<template>
  <div>
    <div class="large-top-spacer" id="cardGrid">
      <div id="link">
        <a href="/#/" class="btn btn-primary-nextgen"
           style="color: white; text-decoration-color: white !important; float: right; margin-right: 50px;"> Show
          grid </a>
      </div>
      <d3-network ref='net' :net-nodes="nodes" :net-links="links" :options="options" @node-click="nodeClickHandler"/>
    </div>
  </div>
</template>

<script>
import D3Network from 'vue-d3-network'
import CardService from "../../services/CardService";

export default {
  name: 'CardMapViewerComponent',
  components: {
    D3Network
  },
  computed: {
    options() {
      return {
        force: 3000,
        size: {h: 1000},
        nodeSize: this.nodeSize,
        nodeLabels: true,
        linkLabels: true,
        canvas: this.canvas
      }
    }
  },
  methods: {
    nodeClickHandler(event, nodeObject) {
      if (this.selectedCard === nodeObject.id) {
        this.$router.push('/card/' + nodeObject.id)
      }
      this.selectedCard = nodeObject.id
    }
  },
  mounted() {
    CardService.getCardConnections().then(response => {
      this.nodes = response.data['nodes'].map(node => ({
        id: node.idCard,
        name: node.title
        // _color: node.color
      }))

      this.links = response.data['connections'].map(connection => ({
        sid: connection.idCard,
        tid: connection.connectedCard,
        _color: "black"
      }))
    })

  },
  data() {


    // Own cards in a different color. -> CardColor
    // CLose after successful connection
    // Move alert to a fixed position at the bottom of the viewport. Watch the z-index! Scrolling should still be possible
    return {
      nodes: [],
      links: [],
      nodeSize: 30,
      canvas: false,
      strLinks: true,
      selectedCard: null
    }
  },

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=PT+Sans');

body {
  font-family: 'PT Sans', sans-serif;
  background-color: #eee;
}

.title {
  position: absolute;
  text-align: center;
  left: 2em;
}

h1, a {
  color: #1aad8d;
  text-decoration: none;
}

ul.menu {
  list-style: none;
  position: absolute;
  z-index: 100;
  min-width: 20em;
  text-align: left;
}

ul.menu li {
  margin-top: 1em;
  position: relative;
}


</style>